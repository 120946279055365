<template>
  <div v-if="this.items">
    <CRow>
      <CCol sm="12">
        <CCard>

          <CCardHeader>
            <br>
            <h5 v-if="items.length">Lista Totem Attivi</h5>
          </CCardHeader>

          <CCardBody v-if="items.length">
            <CDataTable
                :items="items"
                :fields="fields"
                pagination
            >
              <template #comuniCustom="{item}">
                <td>
                  {{ item.comuni.comune }}
                </td>
              </template>

              <template #bottoneVisualizzazione="{item}">
                <td>
                  <CButton
                      class="m-2"
                      color="info"
                      @click="editTotem(item.id)"
                  >
                    Modifica
                  </CButton>

                </td>
              </template>

            </CDataTable>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
  <div v-else>
    <CAlert color="danger">
      <h5>Ci dispiace! Non risultano totem attivi per la sua utenza</h5>
    </CAlert>
  </div>
</template>

<script>

export default {
  data() {
    return {
      items: [],
      fields: [
        'nome_personalizzato',
        'username',
        'password',
        {key: 'comuniCustom', label: 'Comune'},
        {key: 'bottoneVisualizzazione', label: 'Operazioni'},
      ],
    };
  },
  computed: {
    computedItems() {
      return this.items.map(item => {
        return {
          ...item,
          comuniCustom: item.comuni,
        }
      })
    },
  },
  methods: {
    getData() {
      axios.get('/dashboard/api/v0/totem/list').then((response) => {
        if (response.status === 200) {
          console.log(response.data.results)
          this.items = response.data.results;
        }
      }, (error) => {
        console.log(error);
      });
    },
    editTotem(idTotem) {
      this.$router.push({
        name: 'Impostazioni',
        params: {idTotem: idTotem}
      });

    }
  },
  created() {
    this.getData();
  },
  name: "ListaTotem"
}
</script>
